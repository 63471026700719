<template>
    <div id="app" v-if="authenticated">
        <!-- <img alt="Vue logo" src="./assets/logo.png"> -->
        <DataView msg="This is where the data goes for Test Runs ..." />
    </div>
</template>

<script>
    import DataView from './components/DataView.vue';
    import axios from 'axios';

    export default {
        name: 'App',
        components: {
            DataView,
        },
        data: () => ({
            authenticated: false,
            //
        }),
        async created() {
            await this.authenticate();
        },
        methods: {
            authenticate: async function () {
                const vm = this;
                let auth = await this.$msal.authenticate();
                axios.interceptors.request.use(
                    async (request) => {
                        let isHyliionRequest =
                            request.url.includes('hyliion.com') || request.url.includes('localhost') || request.url.includes('127.0.0.1');
                        if (isHyliionRequest) {
                            let msalInstance = vm.$msal;
                            let account = auth.accounts[0];
                            const tokenResponse = await msalInstance.getSilentToken(account, msalInstance.config.auth.scopes);
                            request.headers.authorization = `Bearer ${tokenResponse.accessToken}`;
                        }
                        return request;
                    },
                    (error) => {
                        console.log(error);
                        return Promise.reject(error);
                    }
                );
                this.authenticated = true;
            },
        },
    };
</script>

<style scoped>
    .app-bar {
        background-color: #42b983;
    }
    .dark-adjust {
        background-color: #303030;
    }
    .page-title {
        display: inline-block;
        position: relative;
        left: 40px;
        top: -30px;
        padding-top: 25px;
        color: white;
        font-size: 24px;
        font-family: Roboto;
    }
    .row-title {
        font-weight: bold;
        font-size: 16px;
        text-align: center;
        vertical-align: middle;
    }
</style>
