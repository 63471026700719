var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "data", attrs: { id: "app" } },
    [
      _c(
        "div",
        {
          staticStyle: {
            background: "#43b02a",
            position: "relative",
            height: "80px",
            width: "100%",
          },
        },
        [
          _c("v-app-bar-nav-icon", {
            staticClass: "burger-btn",
            on: {
              click: function ($event) {
                $event.stopPropagation()
                _vm.drawer = !_vm.drawer
              },
            },
          }),
          _c("img", {
            staticStyle: {
              padding: "10px",
              left: "30px",
              position: "relative",
            },
            attrs: {
              src: require("@/assets/hyliiion_logo_with_text.png"),
              title: "v.0.9.0",
              alt: "Hyliion Logo",
            },
            on: {
              click: function ($event) {
                _vm.showSelectors = !_vm.showSelectors
              },
            },
          }),
          _c("p", { staticClass: "page-title" }, [_vm._v("ADX Visualizer")]),
        ],
        1
      ),
      _c(
        "v-app",
        { attrs: { id: "inspire", dark: "" } },
        [
          _c(
            "div",
            [
              _c("v-alert", { attrs: { value: _vm.showAlert, type: "info" } }, [
                _vm._v(_vm._s(_vm.alertCopy)),
              ]),
            ],
            1
          ),
          _c(
            "v-overlay",
            {
              attrs: {
                absolute: _vm.absolute,
                opacity: _vm.opacity,
                value: _vm.overlay,
              },
            },
            [
              _c("v-progress-circular", {
                attrs: { indeterminate: "", size: "64" },
              }),
            ],
            1
          ),
          _c(
            "v-navigation-drawer",
            {
              attrs: { absolute: "", bottom: "", temporary: "" },
              model: {
                value: _vm.drawer,
                callback: function ($$v) {
                  _vm.drawer = $$v
                },
                expression: "drawer",
              },
            },
            _vm._l(_vm.minColumns, function (column) {
              return _c("v-checkbox", {
                key: column.index,
                attrs: { label: "" + column, value: "" + column },
                model: {
                  value: _vm.chartSelect,
                  callback: function ($$v) {
                    _vm.chartSelect = $$v
                  },
                  expression: "chartSelect",
                },
              })
            }),
            1
          ),
          _c(
            "v-container",
            { attrs: { "grid-list-md": "" } },
            [
              _c(
                "v-layout",
                { attrs: { row: "", wrap: "" } },
                [
                  _c(
                    "v-flex",
                    { attrs: { xs12: "", lg3: "" } },
                    [
                      _c("v-select", {
                        attrs: {
                          items: _vm.companyItems,
                          label: "Select Company",
                          solo: "",
                        },
                        on: {
                          input: function ($event) {
                            return _vm.getTruckList()
                          },
                        },
                        model: {
                          value: _vm.companySelect,
                          callback: function ($$v) {
                            _vm.companySelect = $$v
                          },
                          expression: "companySelect",
                        },
                      }),
                    ],
                    1
                  ),
                  _c(
                    "v-flex",
                    { attrs: { xs12: "", lg3: "" } },
                    [
                      _c("v-select", {
                        attrs: {
                          items: _vm.truckItems,
                          label: "Select Truck",
                          solo: "",
                        },
                        model: {
                          value: _vm.truckSelect,
                          callback: function ($$v) {
                            _vm.truckSelect = $$v
                          },
                          expression: "truckSelect",
                        },
                      }),
                    ],
                    1
                  ),
                  _c(
                    "v-flex",
                    { attrs: { xs12: "", lg3: "" } },
                    [
                      _c(
                        "v-menu",
                        {
                          attrs: {
                            "close-on-content-click": false,
                            "nudge-right": 40,
                            transition: "scale-transition",
                            "offset-y": "",
                            "min-width": "auto",
                          },
                          scopedSlots: _vm._u([
                            {
                              key: "activator",
                              fn: function (ref) {
                                var on = ref.on
                                var attrs = ref.attrs
                                return [
                                  _c(
                                    "v-text-field",
                                    _vm._g(
                                      _vm._b(
                                        {
                                          attrs: {
                                            label: "Picker without buttons",
                                            "prepend-icon": "mdi-calendar",
                                            readonly: "",
                                          },
                                          model: {
                                            value: _vm.date1,
                                            callback: function ($$v) {
                                              _vm.date1 = $$v
                                            },
                                            expression: "date1",
                                          },
                                        },
                                        "v-text-field",
                                        attrs,
                                        false
                                      ),
                                      on
                                    )
                                  ),
                                ]
                              },
                            },
                          ]),
                          model: {
                            value: _vm.dateSelect1,
                            callback: function ($$v) {
                              _vm.dateSelect1 = $$v
                            },
                            expression: "dateSelect1",
                          },
                        },
                        [
                          _c("v-date-picker", {
                            attrs: {
                              color: "blue lighten-1",
                              "header-color": "blue",
                            },
                            on: {
                              input: function ($event) {
                                _vm.dateSelect1 = false
                              },
                            },
                            model: {
                              value: _vm.date1,
                              callback: function ($$v) {
                                _vm.date1 = $$v
                              },
                              expression: "date1",
                            },
                          }),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                  _c(
                    "v-flex",
                    { attrs: { xs12: "", lg3: "" } },
                    [
                      _c(
                        "v-menu",
                        {
                          attrs: {
                            "close-on-content-click": false,
                            "nudge-right": 40,
                            transition: "scale-transition",
                            "offset-y": "",
                            "min-width": "auto",
                          },
                          scopedSlots: _vm._u([
                            {
                              key: "activator",
                              fn: function (ref) {
                                var on = ref.on
                                var attrs = ref.attrs
                                return [
                                  _c(
                                    "v-text-field",
                                    _vm._g(
                                      _vm._b(
                                        {
                                          attrs: {
                                            label: "Picker without buttons",
                                            "prepend-icon": "mdi-calendar",
                                            readonly: "",
                                          },
                                          model: {
                                            value: _vm.date2,
                                            callback: function ($$v) {
                                              _vm.date2 = $$v
                                            },
                                            expression: "date2",
                                          },
                                        },
                                        "v-text-field",
                                        attrs,
                                        false
                                      ),
                                      on
                                    )
                                  ),
                                ]
                              },
                            },
                          ]),
                          model: {
                            value: _vm.dateSelect2,
                            callback: function ($$v) {
                              _vm.dateSelect2 = $$v
                            },
                            expression: "dateSelect2",
                          },
                        },
                        [
                          _c("v-date-picker", {
                            attrs: {
                              color: "red lighten-1",
                              "header-color": "red",
                            },
                            on: {
                              input: function ($event) {
                                _vm.dateSelect2 = false
                              },
                            },
                            model: {
                              value: _vm.date2,
                              callback: function ($$v) {
                                _vm.date2 = $$v
                              },
                              expression: "date2",
                            },
                          }),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                ],
                1
              ),
              _c(
                "v-layout",
                { attrs: { row: "", wrap: "" } },
                [
                  _c(
                    "v-flex",
                    { attrs: { xs12: "", lg4: "" } },
                    [
                      _c(
                        "v-btn",
                        {
                          staticStyle: { width: "100%" },
                          attrs: { disabled: _vm.btnState, color: "info" },
                          on: {
                            click: function ($event) {
                              return _vm.modCharts()
                            },
                          },
                        },
                        [_vm._v("Plot Data")]
                      ),
                    ],
                    1
                  ),
                  _c(
                    "v-flex",
                    { attrs: { xs12: "", lg4: "" } },
                    [
                      _c(
                        "v-btn",
                        {
                          staticStyle: { width: "100%" },
                          attrs: { color: "error" },
                          on: {
                            click: function ($event) {
                              return _vm.resetCharts()
                            },
                          },
                        },
                        [_vm._v("Reset")]
                      ),
                    ],
                    1
                  ),
                  _c(
                    "v-flex",
                    { attrs: { xs12: "", lg4: "" } },
                    [
                      _c(
                        "v-btn",
                        {
                          staticStyle: { width: "100%" },
                          attrs: { disabled: _vm.exportReady, color: "info" },
                        },
                        [
                          _c(
                            "download-csv",
                            { attrs: { data: _vm.exportSet } },
                            [_vm._v("Export Data")]
                          ),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                ],
                1
              ),
              _c(
                "v-layout",
                { attrs: { row: "", wrap: "" } },
                _vm._l(_vm.chartOptions, function (option) {
                  return _c(
                    "v-flex",
                    { key: option.id, attrs: { xs12: "", lg12: "" } },
                    [
                      _c(
                        "v-toolbar",
                        { attrs: { color: "black", dark: "" } },
                        [
                          _c("v-app-bar-nav-icon"),
                          _c("v-toolbar-title", [
                            _vm._v(_vm._s(option.title.text)),
                          ]),
                          _c("v-spacer"),
                          _c("v-btn", { attrs: { icon: "" } }, [
                            _c(
                              "a",
                              {
                                on: {
                                  click: function ($event) {
                                    return _vm.$vuetify.goTo("#app")
                                  },
                                },
                              },
                              [_c("v-icon", [_vm._v("mdi-arrow-up-bold")])],
                              1
                            ),
                          ]),
                        ],
                        1
                      ),
                      _c("vue-highcharts", {
                        ref: option.name,
                        refInFor: true,
                        attrs: { options: option },
                      }),
                    ],
                    1
                  )
                }),
                1
              ),
            ],
            1
          ),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }