<!--suppress JSUnresolvedVariable -->
<template>
    <div id="app" class="data">
        <!--<h1>{{ msg }}</h1>-->
        <div style="background: #43b02a; position: relative; height: 80px; width: 100%">
            <v-app-bar-nav-icon class="burger-btn" @click.stop="drawer = !drawer"></v-app-bar-nav-icon>
            <img
                style="padding: 10px; left: 30px; position: relative"
                src="@/assets/hyliiion_logo_with_text.png"
                title="v.0.9.0"
                alt="Hyliion Logo"
                @click="showSelectors = !showSelectors"
            />
            <p class="page-title">ADX Visualizer</p>
        </div>
        <v-app id="inspire" dark>
            <div>
                <v-alert :value="showAlert" type="info">{{ alertCopy }}</v-alert>
            </div>
            <v-overlay :absolute="absolute" :opacity="opacity" :value="overlay">
                <v-progress-circular indeterminate size="64"></v-progress-circular>
            </v-overlay>
            <v-navigation-drawer v-model="drawer" absolute bottom temporary>
                <v-checkbox v-model="chartSelect" v-for="column in minColumns" :key="column.index" :label="`${column}`" :value="`${column}`"></v-checkbox>
            </v-navigation-drawer>
            <v-container grid-list-md>
                <v-layout row wrap>
                    <v-flex xs12 lg3><v-select
                            v-model="companySelect"
                            :items="companyItems"
                            label="Select Company"
                            @input="getTruckList()"
                            solo
                        ></v-select>
                    </v-flex>
                    <v-flex xs12 lg3>
                        <v-select
                            v-model="truckSelect"
                            :items="truckItems"
                            label="Select Truck"
                            solo
                        ></v-select>
                    </v-flex>
                    <v-flex xs12 lg3>
                        <v-menu
                            v-model="dateSelect1"
                            :close-on-content-click="false"
                            :nudge-right="40"
                            transition="scale-transition"
                            offset-y
                            min-width="auto"
                            >
                            <template v-slot:activator="{ on, attrs }">
                                <v-text-field
                                v-model="date1"
                                label="Picker without buttons"
                                prepend-icon="mdi-calendar"
                                readonly
                                v-bind="attrs"
                                v-on="on"
                                ></v-text-field>
                            </template>
                            <v-date-picker color="blue lighten-1" header-color="blue" v-model="date1" @input="dateSelect1 = false"></v-date-picker>
                        </v-menu>
                    </v-flex>
                    <v-flex xs12 lg3>
                        <v-menu
                            v-model="dateSelect2"
                            :close-on-content-click="false"
                            :nudge-right="40"
                            transition="scale-transition"
                            offset-y
                            min-width="auto"
                            >
                            <template v-slot:activator="{ on, attrs }">
                                <v-text-field
                                v-model="date2"
                                label="Picker without buttons"
                                prepend-icon="mdi-calendar"
                                readonly
                                v-bind="attrs"
                                v-on="on"
                                ></v-text-field>
                            </template>
                            <v-date-picker color="red lighten-1" header-color="red" v-model="date2" @input="dateSelect2 = false"></v-date-picker>
                        </v-menu>
                    </v-flex>
                </v-layout>
                <v-layout row wrap>
                    <!-- Buttons -->
                    <v-flex xs12 lg4>
                        <v-btn :disabled="btnState" style="width: 100%" color="info" @click="modCharts()">Plot Data</v-btn>
                    </v-flex>
                    <v-flex xs12 lg4>
                        <v-btn style="width: 100%" color="error" @click="resetCharts()">Reset</v-btn>
                    </v-flex>
                    <v-flex xs12 lg4>
                        <v-btn :disabled="exportReady" style="width: 100%;" color="info">
                            <download-csv :data="exportSet">Export Data</download-csv>
                        </v-btn>
                    </v-flex>
                    <!-- <v-flex xs12 lg3>
                        <v-btn :disabled="btnState" style="width: 100%" color="info" @click="modCharts()">Scatter Chart</v-btn>
                    </v-flex> -->
                    <!-- END Buttons -->
                </v-layout>
                <v-layout row wrap>
                    <!-- Highcharts -->
                    <v-flex xs12 lg12 v-for="option in chartOptions" v-bind:key="option.id">
                        <v-toolbar color="black" dark>
                            <v-app-bar-nav-icon></v-app-bar-nav-icon>
                            <v-toolbar-title>{{option.title.text}}</v-toolbar-title>
                            <v-spacer></v-spacer>
                            <v-btn icon>
                                <a @click="$vuetify.goTo('#app')"><v-icon>mdi-arrow-up-bold</v-icon></a>
                            </v-btn>
                        </v-toolbar>
                        <vue-highcharts :options="option" :ref="option.name"></vue-highcharts>
                    </v-flex>
                    <!-- END Highcharts -->
                </v-layout>
            </v-container>
        </v-app>
    </div>
</template>

<script>
    import axios from 'axios';
    import _ from 'lodash';
    import moment from 'moment';
    import VueHighcharts from 'vue2-highcharts';
    import dtc_data from '../lib/test_dtc_codes.json';

    export default {
        name: 'DataView',
        props: {
            msg: String,
        },
        components: {
            VueHighcharts,
        },
        data: function () {
            return {
                drawer: false,
                chartSelect: [],
                listStop: false,
                btnState: true,
                exportReady: true,
                odoToggle: '',
                odoList: ['Odometer', 'Adj. Odometer', 'Time Index'],
                AUTH_TOKEN_NAME: 'authTokenHyliion',
                auth_token: '',
                showHelpModal: false,
                loginModal: false,
                loginEmail: '',
                credential: '',
                authenticateDialog: true,
                apiKey: '',
                isInvalidKey: false,
                API_KEY_NAME: 'apiKey',
                absolute: false,
                opacity: 0.85,
                overlay: false,
                showAlert: false,
                alertCopy: 'Currently showing no data...',
                queryPath: process.env.VUE_APP_API_PATH_BASE,
                queryString: '',
                lorem: 'Lorem ipsum dolor sit amet, mel at clita quando.',
                date1: moment().format('YYYY-MM-DD'),
                date2: moment().format('YYYY-MM-DD'),
                dateSelect1: false,
                dateSelect2: false,
                startDate: null,
                endDate: moment().unix(),
                companyItems: [],
                companyList: [],
                companySelect: '',
                truckSelect: '',
                activeTruck: 0,
                trucks: [],
                truckItems: [],
                seriesData: [],
                testDataSet: [],
                exportSet: [],
                minColumns: [],
                tenColumns: [],
                charts: [],
                chartOptions: [],
                dataState: false,
                xMin: null,
                xMax: null,
                zoomedChart: '',
                zoomButton: null,
                resetZoomCharts: [],
                colors: ['#00B3FF', '#24EF10', '#972AB0', '#FFF000', '#FB04D8', '#04FB27', '#FF9900', '#0066FF'],
                lineChartOptions: {
                    chart: {
                        backgroundColor: '#000000',
                        type: 'line',
                        zoomType: 'x',
                        name: '',
                        events: {
                            selection: (e) => {
                                const chartName = e.target.options.name;
                                if (e.xAxis) {
                                    this.zoomedChart = chartName;
                                    this.xMin = e.xAxis[0].min;
                                    this.xMax = e.xAxis[0].max;
                                    this.resetZoomCharts = [];
                                    this.zoomCharts(true);
                                } else {
                                    // console.log('reset zoom on fuelCharts...');
                                    this.zoomedChart = '';
                                    this.resetZoomCharts.push(chartName);
                                    this.xMin = null;
                                    this.xMax = null;
                                    this.zoomCharts(false);
                                }
                            },
                        },
                    },
                    title: {
                        text: 'Base Sample',
                        style: {
                            color: '#FFFFFF',
                        },
                    },
                    tooltip: {
                        formatter: function () {
                            let tipString = '';
                            tipString += this.point.series.name + '<br>';
                            tipString += 'gps_position: <b>' + this.point.position + '</b><br>';
                            tipString += 'odometer: <b>' + this.point.odometer + '</b><br>';
                            tipString += 'time: <b>' + this.point.time + '</b><br>';
                            tipString += 'readable time: <b>' + this.point.timeRead + '</b><br>';
                            tipString += 'x: <b>' + this.point.x + '</b><br>';
                            tipString += this.point.series.yAxis.axisTitle.textStr + ': <b>' + this.point.y + '</b><br>';
                            tipString += 'elevation: <b>' + this.point.elevFeet + ' ft.</b><br>';
                            return tipString;
                        },
                    },
                    legend: {
                        itemStyle: {
                            color: '#FFFFFF',
                            fontWeight: 'bold',
                        },
                    },
                    xAxis: {
                        title: {
                            text: 'Time',
                            style: {
                                color: '#FFFFFF',
                            },
                        },
                        labels: {
                            step: 1,
                            style: {
                                color: '#FFFFFF',
                            },
                        },
                        lineColor: '#000000',
                    },
                    yAxis: [
                        {
                            floor: 0,
                            title: {
                                text: 'Data',
                                style: {
                                    color: '#FFFFFF',
                                },
                            },
                            labels: {
                                style: {
                                    color: '#FFFFFF',
                                },
                            },
                            lineColor: '#FFFFFF',
                        },
                        {
                            floor: 0,
                            ceiling: 2,
                            gridLineWidth: 0,
                            visible: true,
                            title: {
                                text: 'DTC Active Codes',
                                style: {
                                    color: '#FF0000',
                                },
                            },
                            labels: {
                                style: {
                                    color: '#FF0000',
                                },
                            },
                            gridLineColor: '#000000',
                            lineColor: '#000000',
                            opposite: true,
                        },
                    ],
                    plotOptions: {
                        series: {
                            turboThreshold: 0,
                            cropThreshold: 0,
                            marker: {
                                enabled: true,
                            },
                        },
                        line: {
                            events: {
                                legendItemClick: (e) => {
                                    let self = this;
                                    // console.log('Captured click on socChart legend item...');
                                    let showState;
                                    let plotName;
                                    setTimeout(function () {
                                        showState = e.target.visible;
                                        plotName = e.target.name;
                                        self.toggleChartLines(plotName, showState);
                                    }, 100);
                                },
                            },
                        },
                    },
                    setOptions: {
                        width: '100%',
                    },
                    series: [
                        {
                            data: [],
                        },
                        {
                            type: 'scatter',
                            name: 'Active DTCs',
                            data: [],
                        }
                    ],
                },
                scatterChartOptions: {
                    chart: {
                        backgroundColor: '#000000',
                        type: 'scatter',
                        zoomType: 'xy',
                        name: '',
                        events: {
                            selection: (e) => {
                                const chartName = e.target.options.name;
                                if (e.xAxis) {
                                    this.zoomedChart = chartName;
                                    this.xMin = e.xAxis[0].min;
                                    this.xMax = e.xAxis[0].max;
                                    this.resetZoomCharts = [];
                                    this.zoomCharts(true);
                                } else {
                                    // console.log('reset zoom on fuelCharts...');
                                    this.zoomedChart = '';
                                    this.resetZoomCharts.push(chartName);
                                    this.xMin = null;
                                    this.xMax = null;
                                    this.zoomCharts(false);
                                }
                            },
                        },
                    },
                    title: {
                        text: 'Base Sample',
                        style: {
                            color: '#FFFFFF',
                        },
                    },
                    tooltip: {
                        formatter: function () {
                            let tipString = '';
                            tipString += this.point.series.name + '<br>';
                            tipString += 'gps_position: <b>' + this.point.position + '</b><br>';
                            tipString += 'odometer: <b>' + this.point.odometer + '</b><br>';
                            tipString += 'time: <b>' + this.point.time + '</b><br>';
                            tipString += 'readable time: <b>' + this.point.timeRead + '</b><br>';
                            tipString += 'x: <b>' + this.point.x + '</b><br>';
                            tipString += 'y: <b>' + this.point.y + '</b><br>';
                            tipString += 'elevation: <b>' + this.point.elevFeet + ' ft.</b><br>';
                            return tipString;
                        },
                    },
                    legend: {
                        itemStyle: {
                            color: '#FFFFFF',
                            fontWeight: 'bold',
                        },
                    },
                    xAxis: {
                        title: {
                            enabled: true,
                            text: "X-Axis"
                        },
                        startOnTick: true,
                        endOnTick: true,
                        showLastLabel: true
                    },
                    yAxis: {
                        title: {
                            text: "Y-Axis"
                        }
                    },
                    plotOptions: {
                        series: {
                            turboThreshold: 0,
                            cropThreshold: 0,
                            marker: {
                                enabled: true,
                            },
                        },
                        line: {
                            events: {
                                legendItemClick: (e) => {
                                    let self = this;
                                    // console.log('Captured click on socChart legend item...');
                                    let showState;
                                    let plotName;
                                    setTimeout(function () {
                                        showState = e.target.visible;
                                        plotName = e.target.name;
                                        self.toggleChartLines(plotName, showState);
                                    }, 100);
                                },
                            },
                        },
                    },
                    setOptions: {
                        width: '100%',
                    },
                    series: [
                        {
                            data: [],
                        },
                    ],
                },
                dtc_array: [],
            };
        },
        created() {
            this.$vuetify.theme.dark = true;
        },
        watch: {
            zoomedChart: function (val) {
                console.log('zoomedChart = ' + val);
            },
            date1: function (val) {
                const vm = this;
                console.log('Date1 -> ' + val);
                vm.startDate = moment(val).unix();
            },
            date2: function (val) {
                const vm = this;
                console.log('Date2 -> ' + val);
                vm.endDate = moment(val).endOf('day').unix();
            },
            truckSelect: function (truck) {
                const vm = this;
                let num = vm.truckLookup(truck);
                vm.activeTruck = num !== undefined ? num : 0;
                vm.btnState = false;
                vm.exportReady = false;
            },
            chartSelect: function (arr) {
                const vm = this;
                let infoStr = '';
                if (arr.length > 4) {
                    vm.drawer = false;
                    infoStr = 'Please select maximum four(4) charts from list.'
                    vm.alertHandler(infoStr, 9000);
                    arr.pop();
                }
                console.info(arr);
            },
        },
        mounted() {
            const vm = this;
            // vm.testData();
            // vm.createDemoCharts();
            vm.getMinSchema();
            vm.getTenSchema();
            vm.getCompanyList();
            vm.parseTestData();
        },
        methods: {
            parseTestData: function () {
                const vm = this;
                let testArr = _.map(dtc_data, function (o) {
                    let tmpActive = o.active ? 0 : 1;
                    return {
                        time: parseInt(o.activation_time_ms) / 1000,
                        active: tmpActive,
                    }
                });
                vm.dtc_array = _.sortBy(testArr, function (o) {
                    return o.time;
                });
                console.info('Test data for DTC:');
                console.info(vm.dtc_array);
            },
            modCharts: function () {
                const vm = this;
                let count;
                if (vm.chartSelect.length > 0) {
                    _.forEach(vm.chartSelect, function (o, index) {
                        let newChartOpts = _.cloneDeep(vm.lineChartOptions);
                        newChartOpts.id = count = index + 1;
                        newChartOpts.name = o + ' Chart';
                        newChartOpts.title.text = o;
                        newChartOpts.yAxis[0].title.text = o;
                        vm.chartOptions.push(newChartOpts);
                    });
                    // temp for scatter demo
                    let scatterOptions = _.cloneDeep(vm.scatterChartOptions);
                    scatterOptions.id = count + 1;
                    scatterOptions.name = "Demo Scatter Chart";
                    scatterOptions.title.text = "Demo Scatter Data"
                    scatterOptions.yAxis.title.text = "";
                    vm.chartOptions.push(scatterOptions);
                    // end scatter plot demo
                    _.delay(vm.initCharts, 300);
                } else {
                    return;
                }
            },
            initCharts: function () {
                const vm = this;
                for (let key in vm.$refs) {
                    let test = vm.$refs[key];
                    vm.charts.push(test[0].chart);
                }
                vm.drawPlots();
            },
            isNullOrUndefined: function (o) {
                return o === null || o === undefined;
            },
            metersToFeet: function (meters) {
                return _.round(meters * 3.2808, 2);
            },
            copyPoint: function (point) {
                const vm = this;
                if (!vm.isNullOrUndefined(vm.markerPoint)) {
                    vm.markerPoint.setMap(null);
                }
                let index = parseInt(point.index);
                let len = point.series.data.length;
                let prevPos, nextPos;
                prevPos = 'Previous Position - ' + point.series.data[index - 1].position;
                let currPos = 'Current Position - ' + point.position;
                nextPos = index < len ? 'Next Position - ' + point.series.data[index + 1].position : 'Next Position - ()';
                let posConv = point.position.split(',');
                posConv[0] = posConv[0].substring(1, posConv[0].length);
                posConv[1] = posConv[1].substring(0, posConv[1].length - 1);
                let centerPoint = {};
                centerPoint.lat = parseFloat(posConv[0]);
                centerPoint.lng = parseFloat(posConv[1]);
                return `${prevPos} | ${currPos} | ${nextPos}`;
            },
            legendNameCheck: function (name) {
                const vm = this;
                let shortName = _.find(vm.legendNames, function (o) {
                    return name.indexOf(o) !== -1;
                });
                return shortName === undefined ? '' : shortName;
            },
            drawPlots: async function () {
                const vm = this;
                vm.progressShow();
                const isTruck = !vm.isNullOrUndefined(vm.activeTruck);
                const isDateStart = !vm.isNullOrUndefined(vm.startDate);
                const isDateEnd = !vm.isNullOrUndefined(vm.endDate);
                if (isTruck && isDateStart && isDateEnd) {
                    vm.queryString = `${vm.queryPath}telemetry/time_series/minute/${vm.activeTruck}/${vm.startDate}/${vm.endDate}`;
                    await vm.getFullSeries();
                    await _.forEach(vm.charts, function (o) {
                        vm.updateChart(o);
                    });
                }
            },
            getCompanyList: async function () {
                const vm = this;
                const query = vm.queryPath + 'company';
                await axios
                    .get(query)
                    .then(response => {
                        const resLength = response.data.length;
                        if (resLength > 0) {
                            vm.companyItems.length = 0;
                            vm.companyList = response.data;
                            _.forEach(response.data, function (o) {
                                vm.companyItems.push(o.name);
                            });
                        vm.companyItems.sort();
                        } else {
                            console.log('Response array for company names is empty...');
                        }
                    })
                    .catch(error => {
                        console.error('Axios error on getting company list ==> ' + error);
                        vm.errorHandler(error);
                    });
            },
            getTruckList: async function () {
                const vm = this;
                const query = vm.queryPath + 'trucks';
                await axios
                    .get(query)
                    .then(response => {
                        const resLength = response.data.length;
                        if (resLength > 0) {
                            vm.truckFilterByCompany(response.data);
                        } else {
                            console.log('Response array for trucks is empty...');
                        }
                    })
                    .catch(error => {
                        console.error('Axios error on getting trucks ==> ' + error);
                        vm.errorHandler(error);
                    });
            },
            truckFilterByCompany: function (allTrucks) {
                const vm = this;
                vm.trucks = allTrucks;
                vm.truckItems = [];
                _.forEach(allTrucks, function (o) {
                    if (o.company === vm.companySelect) {
                        vm.truckItems.push(o.truckinfo.name);
                    }
                });
            },
            truckLookup: function (truck) {
                const vm = this;
                let truckNo = _.find(vm.trucks, function (o) {
                    return o.truckinfo.name === truck;
                });
                return truckNo.id;
            },
            getMinSchema: async function () { //get the one minute data schema column names
                const vm = this;
                const query = vm.queryPath + 'telemetry/schema/minute';
                await axios
                    .get(query)
                    .then((response) => {
                        _.forEach(response.data, function(o) {
                            vm.minColumns.push(o.ColumnName);
                        });
                        // console.info(vm.minColumns);
                    })
                    .catch((error) => {
                        console.error('Axios error on getting minute schema column names ==> ' + error);
                        vm.errorHandler(error);
                    });
            },
            getTenSchema: async function () { //get the 10Hz data schema column names
                const vm = this;
                const query = vm.queryPath + 'telemetry/schema/10Hz';
                await axios
                    .get(query)
                    .then((response) => {
                        _.forEach(response.data, function(o) {
                            vm.tenColumns.push(o.ColumnName);
                        });
                    })
                    .catch((error) => {
                        console.error('Axios error on getting minute schema column names ==> ' + error);
                        vm.errorHandler(error);
                    });
            },
            getFullSeries: async function () {
                const vm = this;
                // const query = vm.queryPath + 'telemetry/time_series/minute/165/1638497148/1638669962';
                console.info(vm.queryString);
                await axios
                    .get(vm.queryString)
                    .then((response) => {
                        _.forEach(response.data, function(o) {
                            vm.seriesData.push(o);
                        });
                        console.info('Series collection length: ' + vm.seriesData.length);
                    })
                    .catch((error) => {
                        console.error('Axios error on getting full series ==> ' + error);
                        vm.errorHandler(error);
                    });
            },
            resetCharts: function () {
                const vm = this;
                _.forEach(vm.charts, function (o) {
                    o.series[0].remove(false);
                    o.redraw();
                    o.zoomOut();
                });
                vm.charts = [];
                vm.chartOptions = [];
                vm.seriesData = [];
                vm.exportSet = [];
                vm.chartSelect = [];
                vm.dateSelect1 = false;
                vm.dateSelect2 = false;
                vm.companySelect = '';
                vm.truckSelect = '';
            },
            alertHandler: function (infoStr, time) {
                const vm = this;
                vm.alertCopy = infoStr;
                vm.showAlert = true;
                setTimeout(function () {
                    vm.showAlert = false;
                    vm.alertCopy = '...';
                }, time);
            },
            errorHandler: function (errorObj) {
                const vm = this;
                let errorDesc = JSON.stringify(errorObj.response);
                vm.alertCopy = errorObj + ' - ' + errorDesc;
                vm.showAlert = true;
                setTimeout(function () {
                    vm.showAlert = false;
                    vm.alertCopy = 'Currently showing no data...';
                }, 9000);
            },
            toggleChartLines: function (name, state) {
                const vm = this;
                let item;
                let testName = vm.legendNameCheck(name);
                for (item of vm.fuelRateCharts.series) {
                    if (item.name.indexOf(testName) > -1 || testName.indexOf(item.name) > -1) {
                        if (state) {
                            item.show();
                        } else {
                            item.hide();
                        }
                    }
                }
                for (item of vm.socCharts.series) {
                    if (item.name.indexOf(testName) > -1 || testName.indexOf(item.name) > -1) {
                        if (state) {
                            item.show();
                        } else {
                            item.hide();
                        }
                    }
                }
            },
            zoomCharts: function (state) {
                const vm = this;
                _.forEach(vm.charts, function (o) {
                    if (o.options.name !== vm.zoomedChart) {
                        if (state) {
                            o.xAxis[0].setExtremes(vm.xMin, vm.xMax);
                        } else {
                            o.xAxis[0].setExtremes(null, null);
                        }
                    }
                });
            },
            updateChart: function (currChart) {
                const vm = this;
                console.info('Chart data:');
                console.info(currChart);
                let odo = 0;
                let index = 0;
                const len = vm.seriesData.length;
                let exportData = [];
                vm.legendNames = [];
                currChart.series = [];
                let dataSet = {
                    name: currChart.name,
                    color: '#42fc73',
                    data: [],
                };
                let currColor = vm.colors[index];
                let nextColor = vm.colors[index + 1];
                dataSet.color = currColor;
                let dtcLength = vm.dtc_array.length;
                for (let i = 0; i < len; i++) {
                    let series = vm.seriesData[i];
                    let codes;
                    if (i < dtcLength) {
                        codes = vm.dtc_array[i];
                    } else {
                        codes = {
                            time: 0,
                            active: false,
                        }
                    }
                    let plotValue = _.get(series, currChart.options.title.text);
                    exportData.push(series);
                    vm.legendNames.push(series.truck_id);
                    let readTime = moment.unix(series.report_time).format('MM/DD/YYYY h:mm:ss A');
                    let elevation = vm.metersToFeet(series.gps_altitude);
                    if (currChart.options.title.text !== 'Demo Scatter Data') {
                        dataSet.data.push({
                            x: i,
                            y: _.round(plotValue, 7),
                            odometer: odo,
                            time: _.round(series.report_time, 2),
                            timeRead: readTime,
                            elevFeet: elevation,
                            position: '(' + _.round(series.latitude, 6) + ', ' + _.round(series.longitude, 6) + ')',
                        });

                    } else {
                        dataSet.data.push({
                            x: _.round(series.truck_fuel_rate, 3),
                            y: _.round(series.truck_engine_speed, 3),
                            odometer: odo,
                            time: _.round(series.report_time, 2),
                            timeRead: readTime,
                            elevFeet: elevation,
                            position: '(' + _.round(series.latitude, 6) + ', ' + _.round(series.longitude, 6) + ')',
                        });
                    }

                    index++;
                }
                vm.exportSet = exportData;
                currChart.addSeries(dataSet);
                vm.addDtcOverlay(currChart);
                // console.info('series added to chart ' + currChart.options.title.text);
                // console.info(currChart);
                vm.progressHide();
                vm.btnState = true; //disable further plots until reset
            },
            addDtcOverlay: function (currChart) {
                const vm = this;
                const len = vm.dtc_array.length;
                let dtcSet = {
                    name: 'DTC Codes',
                    type: 'scatter',
                    color: '#42fc73',
                    data: [],
                }
                for (let i = 0; i < len; i++) {
                    let codes = vm.dtc_array[i];
                    let readTime = moment.unix(codes.time).format('MM/DD/YYYY h:mm:ss A');
                    if (currChart.options.title.text !== 'Demo Scatter Data') {
                        dtcSet.data.push({
                            x: i,
                            y: (codes.active ? currChart.yAxis[0].max : -10),
                            odometer: '---',
                            time: _.round(codes.time, 2),
                            timeRead: readTime,
                            elevFeet: '---',
                            position: 'N/A',
                        });
                    }
                }
                currChart.addSeries(dtcSet);
            },
            progressShow: async function () {
                const vm = this;
                vm.overlay = true;
            },
            progressHide: async function () {
                const vm = this;
                vm.overlay = false;
            },
        },
    };
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>

    .burger-btn {
        padding: 10px;
        left: 10px;
        margin-top: -60px;
        color: #ffffff !important;
    }

    .v-messages {
        min-height: 1px;
    }

    .container {
        max-width: 90%;
    }

    .overlayProgress {
        position: absolute;
        display: block;
        font-size: 27px;
        font-weight: bold;
        top: 56%;
        margin: 0 auto;
        width: 333px;
    }

    .page-title {
        display: inline-block;
        position: relative;
        margin-top: 16px;
        margin-left: 24px;
        color: #ffffff;
        font-size: 1.1rem;
        /* font-family: "Avenir", Helvetica, Arial, sans-serif; */
        line-height: 1;
    }
    .show-selectors {
        display: block;
        position: relative;
        float: right;
        margin-top: 9px;
        margin-right: 9px;
    }
    #map {
        /* vertical-align: top; */
        display: block;
        position: relative;
        width: 100%;
        height: 640px;
    }
    .page-title {
        display: inline-block;
        position: relative;
        left: 40px;
        top: -30px;
        padding-top: 25px;
        color: white;
        font-size: 24px;
        font-family: 'Roboto', sans-serif;
    }

    .data {
        margin-top: 0;
    }
    ul {
        list-style-type: none;
        padding: 0;
    }
    li {
        display: inline-block;
        margin: 0 10px;
    }
    a {
        color: #42b983;
    }
    h3 {
        margin-top: 40px;
        text-align: left;
        font-size: 36px;
        color: #fff;
        font-weight: 300;
        margin-bottom: 10px;
    }

    table.v-table tbody td,
    table.v-table thead th {
        min-width: 3%;
    }

    @media screen and (max-width: 750px) {
        .page-title {
            font-size: 18px;
        }

        h3 {
            text-align: center;
            font-size: 22px;
        }
        table.v-table tbody td {
            font-weight: 400;
            font-size: 9px;
        }
        table.v-table thead th {
            font-weight: 500;
            font-size: 9px;
        }
    }
</style>
