import Vue from 'vue';
import App from './App.vue';
import vuetify from './plugins/vuetify';
import JsonCSV from 'vue-json-csv';
import 'vuetify/dist/vuetify.css';
import '@fortawesome/fontawesome-free/css/all.css';
import { default as msalPlugin } from 'vue-msal-browser';

const msalConfig = {
    auth: {
        tenant: process.env.VUE_APP_TENANT,
        clientId: process.env.VUE_APP_CLIENT_ID,
        authority: process.env.VUE_APP_AUTHORITY,
        redirectUri: window.location.origin, // It has to be configured on your Azure tenant
        scopes: [process.env.VUE_APP_SCOPES],
    },
    cache: {
        cacheLocation: 'localStorage',
    },
    graph: {
        url: 'https://graph.microsoft.com',
        scopes: 'User.Read',
    },
    mode: 'redirect',
};

Vue.use(msalPlugin, msalConfig);

Vue.use(vuetify, {
    iconfont: 'fa',
});

Vue.component('downloadCsv', JsonCSV);

Vue.config.productionTip = false;

new Vue({
    vuetify,
    render: (h) => h(App),
}).$mount('#app');
