var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _vm.authenticated
    ? _c(
        "div",
        { attrs: { id: "app" } },
        [
          _c("DataView", {
            attrs: { msg: "This is where the data goes for Test Runs ..." },
          }),
        ],
        1
      )
    : _vm._e()
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }